import React, { useEffect, useState } from 'react';
import { useGetAllCategoryMutation, useGetAllColorsMutation, useGetAllProductsMutation } from '@services/AllApi';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { icons } from '@utilits/icons';
import { useParams } from 'react-router-dom';
import OurproductMap from '@components/ourProduct/OurproductMap';
import { useDispatch, useSelector } from 'react-redux';
import arrDown from "@assets/icons/arrow-down.svg";
import { setLoader } from '@Slice';
import checkSvg from "@assets/icons/check.svg"

const minDistance = 1;
function FiltersSm({ checkedCategories, setCheckedCategories, selectedColors, setSelectedColors, priceRange, setPriceRange }) {
    const { id: productId } = useParams();
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.HomeSlice.lang);
    const langData = useSelector((state) => state.HomeSlice.langData);
    const apiToken = localStorage.getItem("apiToken");

    const [getAllCategory, { data: categoryData }] = useGetAllCategoryMutation();
    const [getAllProducts, { data: productsData, isLoading: productsLoading, isSuccess: productsSuccess }] = useGetAllProductsMutation();
    const [getAllColors, { data: colorsData, isSuccess: colorsSuccess }] = useGetAllColorsMutation();

    const [expandedFilter, setExpandedFilter] = useState(null);

    const toggleDropdown = (filterName) => {
        setExpandedFilter(expandedFilter === filterName ? null : filterName);
    };

    // Effect to fetch products
    useEffect(() => {
        const productsBody = {
            method: "get_all_products",
            params: {
                category_ids: checkedCategories,
                color_ids: selectedColors,
                min_price: priceRange[0],
                max_price: priceRange[1],
            },
        };

        const timer = setTimeout(() => {
            getAllProducts(productsBody);
        }, 300);

        return () => clearTimeout(timer);
    }, [checkedCategories, selectedColors, priceRange, getAllProducts]);

    // Fetch categories and colors
    useEffect(() => {
        if (apiToken) {
            getAllCategory({ method: "get_all_categories" });
            getAllColors({ method: "get_colors" });
        }
    }, [getAllCategory, getAllColors, apiToken]);

    // Update checked categories on productId change
    useEffect(() => {
        setCheckedCategories([Number(productId)]);
    }, [productId]);

    // Handle category selection
    const handleCategoryChange = (id) => {
        setCheckedCategories((prev) => (prev.includes(id) ? prev.filter((catId) => catId !== id) : [...prev, id]));
    };

    // Handle color selection
    const handleColorChange = (id) => {
        setSelectedColors((prev) => (prev.includes(id) ? prev.filter((colorId) => colorId !== id) : [...prev, id]));
    };

    // Handle price range change
    const handlePriceChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) return;
        if (activeThumb === 0) {
            setPriceRange([Math.min(newValue[0], priceRange[1] - minDistance), priceRange[1]]);
        } else {
            setPriceRange([priceRange[0], Math.max(newValue[1], priceRange[0] + minDistance)]);
        }
    };

    const nameLangKey = `name_${lang}`;
    const currency = (number, currency, lang = undefined) =>
        Intl.NumberFormat(lang, { style: 'currency', currency }).format(number);


    return (
        <div className='flex flex-col gap-[6px] mb-[37px]'>
            {/* Filters Title */}
            <div className='bg-[#F5F5F5] py-[11px] px-[20px] rounded-lg'>
                <p>{langData.Filters}</p>
            </div>

            {/* Product Types */}
            <div className='border border-[#B9BBBE] rounded-lg'>
                <div
                    className='flex items-center justify-between py-[19px] px-[16px] cursor-pointer'
                    onClick={() => toggleDropdown("Product_types")}
                >
                    <p>{langData.Product_types}</p>
                    <img
                        src={arrDown}
                        alt="Arrow Down"
                        className={`transform transition-transform ${expandedFilter === "Product_types" ? "rotate-180" : ""}`}
                    />
                </div>
                {expandedFilter === "Product_types" && (
                    <div className='px-[25px]'>
                        {categoryData?.result.map(
                            (item, index) =>
                                item.parent_id === 0 && (
                                    <div key={index}>
                                        <li className="flex items-center justify-between mb-[12px]">
                                            <div className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="accent-[black] h-4 w-4 rounded-md cursor-default"
                                                    id={`parent_${item.id}`}
                                                    checked={checkedCategories.includes(item.id)}
                                                    onChange={() => handleCategoryChange(item.id)}
                                                />
                                                <label className="ml-[8px]  font-semibold" htmlFor={`parent_${item.id}`}>
                                                    {item[nameLangKey]}
                                                </label>
                                            </div>
                                            <p className="font-semibold">({item.product_count})</p>
                                        </li>
                                        <ul className="ml-[10px] text-[14px]">
                                            {item.all_children?.map((child, childIndex) => (
                                                <li className="my-[8px] flex items-center justify-between" key={childIndex}>
                                                    <div className="flex items-center gap-[8px]">
                                                        <input
                                                            type="checkbox"
                                                            className="accent-[black] h-3 w-3 cursor-default"
                                                            id={`child_${child.id}`}
                                                            checked={checkedCategories.includes(child.id)}
                                                            onChange={() => handleCategoryChange(child.id)}
                                                        />
                                                        <span>{child[nameLangKey]}</span>
                                                    </div>
                                                    <p>({child.product_count})</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )
                        )}
                    </div>
                )}
            </div>

            {/* Filter by Price */}
            <div className='border border-[#B9BBBE] rounded-lg'>
                <div
                    className='flex items-center justify-between py-[19px] px-[16px] cursor-pointer'
                    onClick={() => toggleDropdown("Filter_by_price")}
                >
                    <p>{langData.Filter_by_price}</p>
                    <img
                        src={arrDown}
                        alt="Arrow Down"
                        className={`transform transition-transform ${expandedFilter === "Filter_by_price" ? "rotate-180" : ""}`}
                    />
                </div>
                {expandedFilter === "Filter_by_price" && (
                    <div className='px-[25px]'>
                        <div className="flex items-center gap-[26px] mt-[32px] mb-[24px]">
                            <div className="bg-[#EDEEEF] rounded-[8px]">
                                <p className="text-[20px] py-[10px] px-[20px] text-[#575B5F] font-semibold">
                                    {currency(priceRange[0], 'UZS').replace('UZS', '').replace('soʻm', '').replace(/,/g, ' ').slice(0, -3).replace('.', ' ')}{' '}

                                </p>
                            </div>
                            <div className="bg-[#EDEEEF] rounded-[8px]">
                                <p className="text-[20px] py-[10px] px-[20px] text-[#575B5F] font-semibold">
                                    {currency(priceRange[1], 'UZS').replace('UZS', '').replace('soʻm', '').replace(/,/g, ' ').slice(0, -3).replace('.', ' ')}{' '}

                                </p>
                            </div>
                        </div>
                        <Slider
                            value={priceRange}
                            onChange={handlePriceChange}
                            valueLabelDisplay="auto"
                            color="black"
                            min={0}
                            max={1000000}
                            step={10}
                            disableSwap
                        />
                    </div>
                )}
            </div>

            {/* Colors */}
            <div className='border border-[#B9BBBE] rounded-lg'>
                <div
                    className='flex items-center justify-between py-[19px] px-[16px] cursor-pointer'
                    onClick={() => toggleDropdown("Colors")}
                >
                    <p>{langData.Colors}</p>
                    <img
                        src={arrDown}
                        alt="Arrow Down"
                        className={`transform transition-transform ${expandedFilter === "Colors" ? "rotate-180" : ""}`}
                    />
                </div>
                {expandedFilter === "Colors" && (
                    <div className="flex flex-col gap-[6px] px-[25px] my-[14px] flex-wrap">
                        {colorsSuccess &&
                            colorsData?.result.map((color) => (
                                <div className="flex items-center justify-between" key={color.id}>
                                    <button
                                        style={{ backgroundColor: color.name_en }}
                                        className={`rounded-[8px] w-[48px] h-[48px] flex items-center justify-center cursor-pointer border-2 ${selectedColors.includes(color.id) ? 'border-black' : 'border-gray-500'
                                            }`}
                                        onClick={() => handleColorChange(color.id)}
                                    >
                                        {selectedColors.includes(color.id) && (
                                            <img src={checkSvg} alt="Selected" />
                                        )}
                                    </button>
                                    <p>{color[nameLangKey]}</p>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default FiltersSm;
