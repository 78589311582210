import React, { useEffect } from 'react'
import { icons } from '@utilits/icons'
import { useGetAllCategoryMutation } from '@services/AllApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '@Slice';

export default function Footer() {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const apiToken = localStorage.getItem("apiToken")
  const lang = useSelector((state) => state.HomeSlice.lang)
  const langData = useSelector((state) => state.HomeSlice.langData)
  const [getAllCategory, { data: getAllCategoryData, isLoading: getAllCategoryLoading, isSuccess: getAllCategorySuc }] = useGetAllCategoryMutation();

  useEffect(() => {
    if (apiToken && !getAllCategoryData) {
      getAllCategory({ method: "get_all_categories" });
    }
  }, [apiToken]);

  useEffect(() => {
    dispatch(setLoader(!getAllCategorySuc));
  }, [getAllCategorySuc, setLoader]);

  const NameLangKey = `name_${lang}`;
  const currentYear = new Date().getFullYear();


  return (

    <div className={`container mx-auto lg:mt-[50px] ${location.pathname == "/login" | location.pathname == "/register" | location.pathname == "/otp" && 'hidden'}`}>
      <div className="flex flex-col lg:flex-row lg:items-center justify-between py-[30px]">
        <div className="flex flex-col justify-between w-full pt-[36px] px-[24px] pb-[24px] rounded-xl shadow-[0px_4px_20.9px_0px_#D1D1D140]">
          <span>{icons.logo}</span>
          <div className='mt-[64px]'>
            <div className=" items-center justify-between mb-[24px]">
              <p className='text-[#D7D7D7] text-[12px] mb-[8px]'>{langData.ourNum}</p>
              <p className='font-semibold text-[18px]'>+998 (97) 757 72 27</p>
            </div>
            <div className=" items-end justify-between lg:mt-0">
              <p className='text-[#D7D7D7] text-[12px] mb-[11px]'>{langData.social}</p>
              <div className="flex gap-2">
                <a href="https://www.instagram.com/morobolsin/" target='_blank'>
                  <button className=' text-[25px]'>{icons.instagramblack}</button>
                </a>
                <a href="https://t.me/morobolsin" target='_blank'>
                  <button className=' text-[25px]'>{icons.telegramBlack}</button>
                </a>
                {/* <button className=' text-[25px]'>{icons.facebookBlack}</button> */}
              </div>
            </div>
          </div>
          <div>
            <p className='mt-[24px] text-[16px] font-semibold flex items-center'>{icons.loactionFooter} <span className='ml-[8px]'>{langData.location}</span>:<span className='text-[#595959] ml-[12px] text-[14px] font-normal'>{langData.locationDec}</span> </p>
          </div>
        </div>
        <div className="grid pt-[36px] pl-[24px] pb-[24px]">
          <div className="grid grid-cols-2 lg:grid-cols-3 items-start gap-[24px] lg:gap-0">
            <div className="grid">
              <p className='text-[16px] font-semibold mb-[24px] inline-block'>{langData.products}</p>
              <ul className='grid gap-[20px]'>
                {getAllCategorySuc &&
                  getAllCategoryData.result?.map((item, index) => (
                    item.parent_id === 0 ? (
                      <li onClick={() => navigate(`/product/${item.id}`)} key={index} className='text-[14px] cursor-pointer text-[#595959]'>{item[NameLangKey]}</li>
                    ) : null
                  ))
                }
              </ul>
            </div>
            <div className="grid">
              <p className='text-[16px] font-semibold mb-[24px] inline-block'>{langData.aboutUS}</p>
              <ul className='grid gap-[20px] mb-[24px]'>
                <li className='text-[14px] text-[#595959]'>{langData.main}</li>
              </ul>
              <p className='text-[16px] font-semibold my-[24px] inline-block'>{langData.help}</p>
              <ul className='grid gap-[20px]'>
                <li className='text-[14px] text-[#595959]'>{langData.helpCenter}</li>
                <li className='text-[14px] text-[#595959]'>{langData.contact}</li>
              </ul>
            </div>
            <div className="grid">
              <p className='text-[16px] font-semibold mb-[24px] inline-block'>{langData.law}</p>
              <ul className='grid gap-[20px]'>
                <li className='text-[14px] text-[#595959]'>
                  {langData.tasks}
                </li>
                <li className='text-[14px] text-[#595959]'>{langData.secLaw}</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
      <div className="flex items-center justify-between py-[16px] border-gray-300 border-t-[1px]">
        <p className='text-[14px] text-[#595959]'>© {currentYear} morobolsin - {langData.serviceComp}</p>
        <p className='font-semibold text-[14px] hidden lg:block'>{langData.call24}</p>
      </div>
    </div>

  )
}
