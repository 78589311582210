import React, { useEffect, useState } from 'react';
import leftArrWhite from "@assets/icons/arrow-right.svg";
import arrowDown from "@assets/icons/arrow-down.svg";
import { useDispatch, useSelector } from 'react-redux';
import { setQuantity } from '@Slice';
import { useAddBasketMutation } from '../../services/AllApi';
import { toast } from 'react-toastify';




function EmbroyderyStep4({ lineNum, textLine1, textLine2, selectedFont1, selectedFont2, color1, color2, productName, setSteps, productId }) {
    const selectedSize = useSelector(state => state.HomeSlice.selectedSize)
    const selectedSizeId = useSelector(state => state.HomeSlice.selectedSizeId)
    const langData = useSelector(state => state.HomeSlice.langData)
    const [sizeError, setSizeError] = useState(false)
    const quantity = useSelector(state => state.HomeSlice.quantity)
    const clientToken = localStorage.getItem('clientToken')
    const clientId = localStorage.getItem('clientId')
    const dispatch = useDispatch()
    const [addToBasket, { data: addToBasketData, isLoading: addToBasketLoading, isSuccess: addToBasketSuc }] = useAddBasketMutation();


    const handleAddToBasketFnc = (e) => {
        e.preventDefault()
        if (selectedSize) {
            addToBasket(
                {
                    method: "addToBasket",
                    params: {
                        token: clientToken,
                        product_id: productId,
                        size_id: selectedSizeId,
                        quantity: quantity,
                        has_discount: false,
                        discount: null,
                        has_logo: true,
                        client_id: clientId
                    }
                }
            )
            setSizeError(false)
        }
        else {
            setSizeError(true)
        }
    }


    useEffect(() => {
        if (addToBasketSuc) {
            toast.success(langData.SuccessfullyBasket);
        }

    }, [addToBasketSuc])
    return (
        <div className='flex flex-col'>
            <h1 className='font-medium text-[20px] lg:text-3xl mb-3'>
                {langData.total_amount}
            </h1>

            <div className='bg-embroideryBgImg bg-cover lg:w-3/4 h-[50vh] bg-center'>
                <div className='h-[25px] max-w-[163px] text-[24px] ml-[25%] lg:ml-[31%] mt-[40%] lg:mt-[35%] flex items-center justify-center'>
                    <p style={{ fontFamily: selectedFont1, color: color1 }}>{textLine1}</p>
                </div>
                {lineNum > 1 && (
                    <div className='h-[25px] max-w-[163px] ml-[25%] lg:ml-[31%] mt-[5px] flex items-center justify-center text-[24px]'>
                        <p style={{ fontFamily: selectedFont2, color: color2 }}>{textLine2}</p>
                    </div>
                )}
            </div>
            <h2 className='mt-[32px] text-[20px] lg:text-[24px] font-semibold'>
                {productName}
            </h2>
            <p className='mt-[24px] text-[20px] lg:text-[24px]'>{langData.Size}: {selectedSize} </p>
            <div className='mt-[24px] flex items-center justify-between lg:w-3/4'>
                <div className='flex items-center gap-[12px] '>
                    <p className='text-[20px] lg:text-[24px]'>{langData.Quantity}:</p>
                    <input value={quantity} onChange={(e) => dispatch(setQuantity(e.target.value))} className='border border-[#B9BBBE] w-[60px] lg:w-[110px] py-[19px] px-[16px] rounded-[4px]' type="text" placeholder='1' />
                </div>
                {/* <h3 className='text-[24px] font-semibold'>750 000 UZS</h3> */}
            </div>

            <div className='h-[1px] w-full bg-gray-400 my-[32px]'>
            </div>
            <p className='text-[20px] font-semibold mb-[24px] flex items-center justify-between lg:w-1/2'>+ {langData.Embroidery_text} <span>{lineNum > 1 ? "60 000" : "30 000"} {langData.sum}</span></p>
            <div className='lg:w-1/2 flex flex-col gap-[16px]'>
                <h3 className='flex items-center justify-between text-[20px] font-semibold'>{langData.firstLine}: <p className='font-semibold'>{textLine1}</p></h3>
                <h3 className='flex items-center justify-between text-[20px] '>{langData.shrift}: <p className=''>{selectedFont1}</p></h3>
                <h3 className='flex items-center justify-between text-[20px] '>{langData.color}:  <p className='flex items-center gap-2'> <div style={{ backgroundColor: color1 }} className={` w-[24px] h-[24px] border border-[#DCDDDF] rounded-full`}></div>{color1}</p></h3>
            </div>
            <div className='h-[1px] w-full bg-gray-400 my-[32px]'>
            </div>
            {
                lineNum > 1 &&
                <div className='lg:w-1/2 flex flex-col gap-[16px]'>
                    <h3 className='flex items-center justify-between text-[20px] font-semibold'>{langData.secondLine}: <p className='font-semibold'>{textLine2}</p></h3>
                    <h3 className='flex items-center justify-between text-[20px] '>{langData.shrift}: <p className=''>{selectedFont2}</p></h3>
                    <h3 className='flex items-center justify-between text-[20px] '>{langData.color}:  <p className='flex items-center gap-2'> <div style={{ backgroundColor: color2 }} className={` w-[24px] h-[24px] border border-[#DCDDDF] rounded-full`}></div>{color2}</p></h3>
                </div>
            }
            <div className='flex items-center justify-between mt-8'>
                <img onClick={() => setSteps(3)} className='w-9 h-9 rotate-[90deg] cursor-pointer' src={arrowDown} alt="" />
                <button onClick={(e) => handleAddToBasketFnc(e)} className='p-3 lg:p-6 text-[#fff] flex items-center gap-6 rounded-lg self-end bg-[#1A213D]'>
                    {langData.Add_to_Cart} <img src={leftArrWhite} alt="" />
                </button>
            </div>
        </div>
    );
}

export default EmbroyderyStep4;
