import React, { useEffect, useState } from 'react';
import { useGetAllProductsMutation, useGetFavoritesMutation } from '@services/AllApi';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '@Slice';

import FavoritesMap from './FavoritesMap';

function Favorites() {
    const [favoriteProducts, setFavoriteProducts] = useState([]); // State to store filtered products
    const [favoriteProductsId, setFavoriteProductsId] = useState([]); // State to store filtered products
    const dispatch = useDispatch()
    const langData = useSelector(state => state.HomeSlice.langData)

    const clientToken = localStorage.getItem('clientToken');
    const apiToken = localStorage.getItem('apiToken');
    const clientId = localStorage.getItem('clientId');

    // API hooks
    const [getFavorites, { data: getFavoritesData, isLoading: getFavoritesLoading }] = useGetFavoritesMutation();
    const [getAllProducts, { data: getAllProductsData, isLoading: getAllProductsLoading }] = useGetAllProductsMutation();

    // Fetch favorites on component mount
    useEffect(() => {
        getFavorites({
            method: "getFavorites",
            params: {
                token: clientToken,
                client_id: clientId,
            },
        });
    }, [getFavorites, clientToken, clientId]);

    // Fetch all products if the API token exists
    useEffect(() => {
        if (apiToken) {
            getAllProducts({ method: "get_all_products" });
        }
    }, [getAllProducts, apiToken]);

    // Dispatch loader states
    useEffect(() => {
        dispatch(setLoader(getAllProductsLoading));
    }, [dispatch, getAllProductsLoading]);

    useEffect(() => {
        if (getFavoritesData?.result && Array.isArray(getAllProductsData?.result?.products)) {
            // Extract favorite product IDs
            const favoriteIds = getFavoritesData.result.map((fav) => fav.product_id);
            setFavoriteProductsId(favoriteIds); // Update the IDs state

            // Filter products based on favorite IDs
            const filteredProducts = getAllProductsData.result.products.filter((product) =>
                favoriteIds.includes(product.id)
            );
            setFavoriteProducts(filteredProducts); // Update favorite products
        } else {
            console.warn("Invalid data structure:", {
                favorites: getFavoritesData?.result,
                products: getAllProductsData?.result?.products,
            });
        }
    }, [getFavoritesData, getAllProductsData]);



    return (
        <div className="container mx-auto">
            <h2 className="text-lg font-semibold my-4">{langData.FavoriteProducts}</h2>
            {favoriteProducts.length === 0 ? (
                <p>No favorite products found.</p>
            ) : (
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                    {favoriteProducts.map((product) => (
                        <FavoritesMap item={product} />
                    ))}
                </div>
            )}
        </div>
    );
}

export default Favorites;
