import React, { useState, useRef, useEffect } from 'react';
import loginBg from "@assets/imgs/loginBg.png";
import logo from "@assets/icons/logo.svg";
import logoWhite from "@assets/icons/logoWhite.svg";
import googleBg from "@assets/icons/googleBg.svg";
import appleBg from "@assets/icons/appleBg.svg";
import facebookBg from "@assets/icons/facebookBg.svg";
import { useNavigate } from 'react-router-dom';
import { useRegisterMutation } from '@services/AllApi';
import { useSelector } from 'react-redux';



function Register() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [contactInfo, setContactInfo] = useState(""); // Combined state for email or phone
  const [isEmail, setIsEmail] = useState(false);
  const [password, setPassword] = useState("");
  const langData = useSelector((state) => state.HomeSlice.langData)
  const [register, { data: registerData, isSuccess: registerSuc }] = useRegisterMutation();

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
    }
    return value;
  };

  const handleContactInfoChange = (e) => {
    let value = e.target.value;
    const emailCheck = /\S+@\S+\.\S+/.test(value);
    setIsEmail(emailCheck);
    if (!emailCheck) {
      setContactInfo(formatPhoneNumber(value));
    } else {
      setContactInfo(value.replace(/^\+998\s?/, '')); // Remove +998 if it's an email
    }
  };

  const registerFnc = (e) => {
    e.preventDefault();
    if (name && surname && contactInfo && password) {
      const emailCheck = /\S+@\S+\.\S+/.test(contactInfo);
      localStorage.setItem("clientEmail", emailCheck ? contactInfo : "")
      register({
        method: "registerClient",
        params: {
          phone: emailCheck ? "" : contactInfo,
          email: emailCheck ? contactInfo : "",
          name: name,
          surname: surname,
          password: password
        }
      });
    }
  };

  useEffect(() => {
    if (registerData?.status == true) {
      // localStorage.setItem("clientEmail",)
      navigate('/otp')
    }
  }, [registerData])


  return (
    <div style={{ backgroundImage: `url(${loginBg})` }} className='bg-cover w-full bg-center p-[16px] flex items-start justify-between'>
      <img onClick={() => navigate("/")} className='p-[60px] cursor-pointer' src={logo} alt="" />
      <form onSubmit={registerFnc} className='px-[24px] py-[40px] w-[493px] rounded-[16px] flex items-center justify-between flex-col bg-[white]'>
        <div className='w-full'>
          <div className='bg-[#1A213D] flex items-center justify-center w-[70%] mx-[auto] py-[22px] rounded-[12px]'>
            <img src={logoWhite} alt="" />
          </div>
          <h1 className='text-[24px] extraBoldJeko text-center mt-[29px] mb-[9px]'>{langData.register}</h1>
          <p className='text-[14px] JekoMedium text-center text-[#AAAAAA] font-normal w-[309px] mx-auto'>{langData.registerText}</p>
          <p className='text-[14px] mt-[12px] JekoMedium text-center text-[red] font-normal w-[309px] mx-auto'>{registerData?.status === false && registerData.error.message ? registerData.error.message : ''}</p>
          <input required onChange={(e) => setName(e.target.value)} className='bg-[#F6F6F6] JekoMedium w-full mt-[24px] rounded-[72px] py-[18px] px-[24px] placeholder-[#AAAAAA] text-[14px] !outline-[#cecece]' placeholder={langData.name} type="text" />
          <input required onChange={(e) => setSurname(e.target.value)} className='bg-[#F6F6F6] JekoMedium w-full mt-[24px] rounded-[72px] py-[18px] px-[24px] placeholder-[#AAAAAA] text-[14px] !outline-[#cecece]' placeholder={langData.surname} type="text" />
          <input required value={contactInfo} onChange={handleContactInfoChange} className='bg-[#F6F6F6] JekoMedium w-full mt-[24px] rounded-[72px] py-[18px] px-[24px] placeholder-[#AAAAAA] text-[14px] !outline-[#cecece]' placeholder={langData.emailOrTelNumber} type="text" />
          <input required onChange={(e) => setPassword(e.target.value)} className='bg-[#F6F6F6] JekoMedium w-full mt-[24px] rounded-[72px] py-[18px] px-[24px] placeholder-[#AAAAAA] text-[14px] !outline-[#cecece]' placeholder="password" type="password" />
        </div>
        <div className='w-full mt-[10%]'>
          <button onClick={() => navigate('/login')} className='JekoMedium rounded-[72px] w-full text-[16px] border !border-[black] py-[15px]'>{langData.login}</button>
          <button type='submit' className='bg-[#D2D3D8] JekoMedium rounded-[72px] w-full text-[16px] text-[#fff] py-[15px] mt-[12px]'>{langData.register}</button>
          <div className='flex items-center justify-between gap-[10px] mt-[30px]'>
            <div className='bg-[#f6f6f6] w-full h-[1px]'></div>
            <p className='w-full text-[12px] whitespace-nowrap text-[#aaaaaa]'>{langData.orThrowThis}</p>
            <div className='bg-[#f6f6f6] w-full h-[1px]'></div>
          </div>
          <div className='mt-[24px] justify-center flex items-center gap-[30px]'>
            <img className='cursor-pointer' src={googleBg} alt="" />
            <img className='cursor-pointer' src={appleBg} alt="" />
            <img className='cursor-pointer' src={facebookBg} alt="" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default Register;
