import React, { useEffect, useState } from "react";
import xIcon from "@assets/icons/x-02.svg";
import { useDeleteBasketMutation, useGetProductDetailMutation } from "../../services/AllApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../Slice";

function ProductList({ basketData, productData }) {
    const clientToken = localStorage.getItem("clientToken");
    const clientId = localStorage.getItem("clientId");
    const langData = useSelector(state => state.HomeSlice.langData)
    const lang = useSelector(state => state.HomeSlice.lang)
    const [detailedProducts, setDetailedProducts] = useState([]);
    const dispatch = useDispatch();
    const apiToken = localStorage.getItem("apiToken");
    const [removeBasket, { isSuccess: removeBasketSuc }] = useDeleteBasketMutation();
    const [getProductDetail, { data: getProductDetailData, isLoading: getProductDetailLoading }] = useGetProductDetailMutation();

    // Format currency helper
    const formatCurrency = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " UZS";
    };


    const nameLangKey = `name_${lang}`


    // Fetch product details for each basket item
    useEffect(() => {
        if (apiToken) {
            const fetchDetails = async () => {
                const promises = basketData.result.map((basketItem) =>
                    getProductDetail({
                        method: "product_details",
                        params: {
                            id: basketItem.product_id,
                        },
                    }).unwrap()
                );

                const details = await Promise.all(promises);

                const enrichedProducts = basketData.result.map((basketItem, index) => {
                    const product = details[index]?.result;


                    if (!product) {
                        console.warn(`Product with ID ${basketItem.product_id} not found.`);
                        return null;
                    }

                    // Get size from sizes array based on basketItem.size_id
                    const sizeAttribute = product.sizes?.find(
                        (size) => size.id === Number(basketItem.size_id)
                    );

                    const applicablePriceRange = product.price_ranges?.find(
                        (range) =>
                            basketItem.quantity >= parseInt(range.from) &&
                            basketItem.quantity <= parseInt(range.to)
                    );

                    const unitPrice = applicablePriceRange
                        ? applicablePriceRange.price
                        : product.price;

                    return {
                        id: basketItem.id,
                        name: product[nameLangKey],
                        img: product.files?.[0]?.path || "",
                        details: product.colors?.map((color) => color[nameLangKey]).join(", ") || "",
                        size: sizeAttribute?.value,
                        price: formatCurrency(unitPrice),
                        quantity: basketItem.quantity,
                        total: formatCurrency(unitPrice * basketItem.quantity),
                    };
                });

                setDetailedProducts(enrichedProducts.filter(Boolean));
            };

            fetchDetails();
        }
    }, [apiToken, basketData, getProductDetail]);

    // Dispatch loader
    useEffect(() => {
        dispatch(setLoader(getProductDetailLoading));
    }, [getProductDetailLoading, dispatch]);

    // Remove product from basket
    const deleteProduct = (basketId) => {
        removeBasket({
            method: "removeBasket",
            params: {
                token: clientToken,
                basket_id: basketId,
                client_id: clientId,
            },
        });
        toast.success(langData.SuccessfullyRemoved);
    };

    // Reload on basket removal success
    useEffect(() => {
        if (removeBasketSuc) {
            window.location.reload();
        }
    }, [removeBasketSuc]);

    console.log(detailedProducts)

    return (
        <div className="container mx-auto lg:mt-8 lg:px-4 flex lg:block flex-col lg:flex-row">
            <div className="hidden md:grid grid-cols-12 gap-[30px] text-lg mb-4 font-semibold text-gray-800">
                <div className="col-span-5 text-center">{langData.product}</div>
                <div className="col-span-3 text-left">{langData.price}</div>
                <div className="col-span-2 text-center">{langData.quantity}</div>
                <div className="col-span-2 text-right">{langData.finalPrice}</div>
            </div>
            {detailedProducts.map((product, index) => (
                <div
                    key={product.id || index}
                    className="lg:grid lg:grid-cols-12 flex flex-col lg:items-center border-t pt-4 gap-4 py-6"
                >
                    <div className="flex items-center gap-4 col-span-5">
                        <img
                            src={xIcon}
                            onClick={() => deleteProduct(product.id)}
                            alt="Remove"
                            className="w-5 h-5 cursor-pointer"
                        />
                        <img
                            src={`https://backend.morobolsin.uz/${product.img}`}
                            alt={product.name}
                            className="w-[80px] h-[80px] object-cover rounded-lg border"
                        />
                        <div className="text-gray-700 text-sm">
                            <p className="font-medium text-gray-900">{product.name}</p>
                            <p className="text-[#B6B6B6] whitespace-pre-line leading-5">
                                {product.size && `${product.size} | `} {product.details}
                            </p>
                        </div>
                    </div>

                    <div className="col-span-3 hidden lg:block text-gray-900 text-sm text-left">
                        <p>{product.price}</p>
                    </div>
                    <div className="col-span-2 hidden lg:flex items-center justify-center gap-2 text-sm font-medium">
                        <span>{product.quantity}</span>
                    </div>
                    <div className="col-span-2 hidden lg:block text-right text-[#1D51FF] text-sm font-medium">
                        {product.total}
                    </div>
                    <div className="flex items-center justify-between lg:hidden">
                        <div className="col-span-3 text-gray-900 text-sm text-left">
                            <p>{product.price}</p>
                        </div>
                        <div className="col-span-2 flex items-center justify-center gap-2 text-sm font-medium">
                            <span>{product.quantity}</span>
                        </div>
                        <div className="col-span-2 text-right text-[#1D51FF] text-sm font-medium">
                            {product.total}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ProductList;
