import React, { useEffect } from 'react';
import map from "@assets/icons/map.svg";
import arrDown from "@assets/icons/arrowdown.svg";
import phone from "@assets/icons/phone.svg";
import clock from "@assets/icons/clock.svg";
import earth from "@assets/icons/earth.svg";
import uzFlag from "@assets/icons/uzbFlag.svg";
import ruFlag from "@assets/icons/rusFlag.svg";
import enFlag from "@assets/icons/ukFlag.svg";
import checkFlag from "@assets/icons/checkFlag.svg";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from '@Slice';

function BlackTop() {
    const location = useLocation();
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.HomeSlice.lang);
    const langData = useSelector((state) => state.HomeSlice.langData);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (key) => {
        dispatch(setLang(key));
        window.location.href = `?lng=${key}`;
        handleClose();
    };

    useEffect(() => {
        if (window.location.search.length > 4) {
            dispatch(setLang(window.location.search.replace('?lng=', '')));
        }
    }, []);

    const languages = {
        uz: { label: 'O’zbek tili', flag: uzFlag },
        ru: { label: 'Русский язык', flag: ruFlag },
        en: { label: 'English', flag: enFlag }
    };

    return (
        <div className={`bg-[#2E2E2E] hidden h-[50px] items-center ${location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/otp" ? 'lg:hidden' : "lg:flex"}`}>
            <div className='container mx-auto flex items-center justify-between'>
                <div className='flex items-center gap-[26px] text-[#fff] text-[12px] font-light'>
                    <p className='flex items-center gap-[8px]'><img src={map} alt="" />{langData.shopping_center} - TOSHKENT</p>
                    <a href="tel:+998977577227">
                        <p className='flex items-center gap-[8px]'>
                            <img src={phone} alt="" />
                            +998 (97) 757 72 27
                        </p>
                    </a>
                </div>
                <div className='flex items-center gap-[26px] text-[#fff] text-[12px] font-light'>
                    <p className='flex items-center gap-[8px]'><img src={clock} alt="" />10:00 AM - 9:00 PM</p>
                    <p onClick={handleClick} className='flex items-center gap-[8px] cursor-pointer'>
                        <img src={earth} alt="" />
                        {languages[lang]?.label}
                        <img className='cursor-pointer' src={arrDown} alt="" />
                    </p>
                    <Menu
                        id="language-menu"
                        className=''
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {Object.keys(languages).map((key) => (
                            <MenuItem
                                key={key}
                                className='flex items-center gap-[10px] capitalize'
                                onClick={() => handleMenuItemClick(key)}
                            >
                                <img src={languages[key].flag} alt={`${languages[key].label} Flag`} className="" />
                                <span className='flex-1'>{languages[key].label}</span>
                                {lang === key && <img src={checkFlag} alt="Selected" className="w-[16px] h-[16px]" />}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </div>
        </div>
    );
}

export default BlackTop;
