import React, { useState } from 'react';
import check from "@assets/icons/check.svg";
import leftArrWhite from "@assets/icons/arrow-right.svg";
import arrDown from "@assets/icons/arrow-down.svg";
import { useSelector } from 'react-redux';

function EmbroyderyStep1({ lineNum, setLineNum, setSteps }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const langData = useSelector(state => state.HomeSlice.langData);

    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    return (
        <div className='flex flex-col'>
            <h1 className='font-medium text-[20px] lg:text-3xl'>
                {langData.How_many_rows_for_embroidery_text}
            </h1>
            <p className='lg:text-xl my-4 text-[#84888E]'>
                {langData.You_can_choose_1_or_2_rows_of_embroidery_text_on_the_clothing}
            </p>
            {/* Background image container */}
            <div className=' bg-embroideryBgImg bg-cover bg-center bg-fixed lg:w-3/4 h-[50vh]'>
                {/* First line text box */}
                <div className=' bg-[#fff] h-[25px] max-w-[100px] lg:max-w-[163px] mt-[45%] lg:mt-[35%] ml-[32%] transform  flex items-center justify-center'>
                </div>
                {/* Second line text box */}
                {lineNum > 1 && (
                    <div className=' bg-[#fff] h-[25px] max-w-[100px] lg:max-w-[163px] mt-[12px] ml-[32%]   flex items-center justify-center'>
                    </div>
                )}
            </div>
            <p className='text-base my-3 lg:my-4 text-[#84888E]'>
                {langData.Select_the_number_of_embroidery_rows}
            </p>
            {/* Dropdown for selecting the number of rows */}
            <div
                onClick={toggleDropdown}
                className='relative lg:w-3/4 cursor-pointer flex items-center border border-[#B9BBBE] py-[15px] lg:py-[19px] px-[16px] rounded-lg justify-between'
            >
                {lineNum === 1 ? langData.row_for_first : langData.row_for_second}
                <img src={arrDown} alt="" />
                {isDropdownOpen && (
                    <div className='absolute bg-[#fff] border border-[#B9BBBE] left-0 top-[100%] p-[16px] rounded-[4px] w-full'>
                        <ul className='text-[14px] lg:text-[16px]'>
                            <li
                                className='border-b pb-[16px] flex items-center justify-between'
                                onClick={() => setLineNum(1)}
                            >
                                {langData.row_for_first}
                                {lineNum === 1 && <img src={check} alt="" />}
                            </li>
                            <li
                                className='pt-[16px] flex items-center justify-between'
                                onClick={() => setLineNum(2)}
                            >
                                {langData.row_for_second}
                                {lineNum === 2 && <img src={check} alt="" />}
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            {/* Next button */}
            <button
                onClick={() => setSteps(2)}
                className='lg:p-6 p-3 text-[#fff] flex items-center gap-6 rounded-lg mt-8 self-end bg-[#1A213D]'
            >
                {langData.nextButton} <img src={leftArrWhite} alt="" />
            </button>
        </div>
    );
}

export default EmbroyderyStep1;
