import React, { useEffect } from 'react';
import Banner from '@components/banner';
import Category from '@components/category';
import TrendProducts from '@components/trendProducts';
import Order from '@components/ourorder';
import Ourproduct from '@components/ourProduct';
import Bannertwo from '@components/banner2';
import Partners from '@components/partners';
import Support from '@components/support';
import phoneCall from "@assets/icons/phone-call-01.svg";

function Home() {
  useEffect(() => {
    (function (w, d, u) {
      const s = d.createElement('script');
      s.async = true;
      s.src = `${u}?${(Date.now() / 60000) | 0}`;
      const h = d.getElementsByTagName('script')[0];
      h.parentNode.insertBefore(s, h);
    })(window, document, 'https://cdn-ru.bitrix24.kz/b31669026/crm/site_button/loader_1_yb10bn.js');
  
  }, [])



  return (
    <div>
      <Banner />
      <Category />
      <TrendProducts />
      <Order />
      <Ourproduct />
      <Bannertwo />
      <Partners />
      <Support />
    </div>
  );
}

export default Home;
