import React, { useState } from 'react';
import profileImg from "@assets/imgs/profile.png";
import { useSelector } from 'react-redux';

function Profile() {
  const [email, setEmail] = useState(localStorage.getItem("clientEmail"));
  const langData = useSelector(state => state.HomeSlice.langData)

  return (
    <div className="container mx-auto py-8">
      <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
        <div
          className="md:w-1/2 hidden md:block h-[360px] md:h-auto bg-cover bg-left"
          style={{ backgroundImage: `url(${profileImg})` }}
        ></div>
        <div className="md:w-1/2 p-8">
          <h2 className="text-[24px] lg:text-[36px] font-bold mb-6">{langData.profileInfo}</h2>
          <form className="space-y-4">
            <div>
              <label className="block text-[16px] lg:text-[20px] font-medium text-gray-700">{langData.name}</label>
              <input
                type="text"
                placeholder="Asadulloh"
                className="mt-1 block w-full rounded-md p-[16px] border-[#2C2D30] border text-[16px] lg:text-[20px]"
              />
            </div>
            <div>
              <label className="block text-[16px] lg:text-[20px] font-medium text-gray-700">{langData.surname}</label>
              <input
                type="text"
                placeholder="Nematov"
                className="mt-1 block w-full rounded-md p-[16px] border-[#2C2D30] border text-[16px] lg:text-[20px]"
              />
            </div>
            <div>
              <label className="block text-[16px] lg:text-[20px] font-medium text-gray-700">{langData.phoneNumber}</label>
              <input
                type="text"
                placeholder="+998 98 123 45 67"
                className="mt-1 block w-full rounded-md p-[16px] border-[#2C2D30] border text-[16px] lg:text-[20px]"
              />
            </div>
            <div>
              <label className="block text-[16px] lg:text-[20px] font-medium text-gray-700">E-mail</label>
              <input
                type="email"
                value={email}
                placeholder="anematov41@gmail.com"
                className="mt-1 block w-full rounded-md p-[16px] border-[#2C2D30] border text-[16px] lg:text-[20px]"
              />
            </div>
            <button
              type="submit"
              disabled
              className="w-full bg-[#1a213d] cursor-not-allowed text-white py-[16px] lg:py-[20px] rounded-md"
            >
              {langData.change}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;
