import React, { useEffect, useState } from 'react';
import phone from "@assets/icons/phone.svg";
import phoneBlack from "@assets/icons/phoneBlack.svg";
import heart from "@assets/icons/like.svg";
import map from "@assets/icons/map.svg";
import box from "@assets/icons/box.svg";
import clock from "@assets/icons/clock.svg";
import login from "@assets/icons/login.svg";
import arrowDown from "@assets/icons/arrow-down.svg";
import earth from "@assets/icons/earth.svg";
import arrowRight from "@assets/icons/arrRightBlack.svg";
import profileIcon from "@assets/icons/profileIcon.png";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAllCategoryMutation } from '@services/AllApi';
import { setLang, setLoader, setOpenDropdown } from '../../Slice';

function SmDropdown() {
  const clientToken = localStorage.getItem('clientToken');
  const apiToken = localStorage.getItem("apiToken");
  const dispatch = useDispatch();
  const [getAllCategory, { data: getAllCategoryData, isLoading: getAllCategoryLoading, isSuccess: getAllCategorySuc }] = useGetAllCategoryMutation();
  const langData = useSelector(state => state.HomeSlice.langData);
  const lang = useSelector(state => state.HomeSlice.lang);
  const navigate = useNavigate();

  // State to manage expanded parent categories
  const [expandedCategory, setExpandedCategory] = useState(null);

  const handleNavigate = (item) => {
    navigate(`/product/${item.id}`);
    dispatch(setOpenDropdown(false))
  };

  useEffect(() => {
    if (apiToken && !getAllCategoryData) {
      getAllCategory({ method: "get_all_categories" });
    }
  }, [getAllCategory, apiToken]);

  useEffect(() => {
    dispatch(setLoader(!getAllCategorySuc));
  }, [getAllCategorySuc, setLoader]);

  const NameLangKey = `name_${lang}`;

  const toggleCategory = (id) => {
    setExpandedCategory(expandedCategory === id ? null : id);
  };

  const handleMenuItemClick = (key) => {
    dispatch(setLang(key));
    window.location.href = `?lng=${key}`;
  };
  return (
    <div>
      <div className='z-[99999] px-4 py-[20px] lg:hidden h-auto bg-[#fff]'>
        <div className='flex items-center justify-between'>
          <a href="tel:+998977577227">
            <p className='flex text-[16px] items-center gap-[8px]'>
              <img src={phoneBlack} alt="Phone Icon" />
              +998(97) 757 72 27
            </p>
          </a>
          <div className='items-center gap-[25px] flex lg:hidden'>
            <img onClick={() => (
              dispatch(setOpenDropdown(false)),
              navigate("/favorites"))}
              src={heart} alt="Heart" className='cursor-pointer w-5' />
            <img onClick={() => (
              dispatch(setOpenDropdown(false)),
              navigate("/basket")
            )} src={box} alt="Box" className='cursor-pointer w-5' />
            {clientToken ? (
              <img onClick={() => (
                dispatch(setOpenDropdown(false)),
                navigate("/profile")
              )} className='w-10 h-10' src={profileIcon} alt="Profile" />
            ) : (
              <p
                onClick={() => (
                  dispatch(setOpenDropdown(false)),
                  navigate("/login")
                )}
                className='flex items-center text-[16px] gap-[12px] cursor-pointer'
              >
                <img className='w-5' src={login} alt="Login" /> {langData.login}
              </p>
            )}
          </div>
        </div>
        {
          getAllCategoryData?.result.map((item) => (
            item.parent_id === 0 && (
              <div key={item.id} className="relative mt-3">
                {/* Parent category */}
                <div className='flex items-center'>
                  <button onClick={() => handleNavigate(item)}
                    className='flex  items-center justify-between w-full text-[20px] rounded-md'
                  >
                    {item[NameLangKey]}
                  </button>
                  {
                    item.all_children.length > 0 &&
                    <span onClick={() => toggleCategory(item.id)} className={`transform transition-transform ${expandedCategory === item.id ? 'rotate-180' : ''}`}>
                      <img src={arrowDown} alt="" />
                    </span>
                  }
                </div>
                {/* Child categories */}
                {expandedCategory === item.id && (
                  <ul className='grid bg-[#F5F5F5] rounded-lg px-[16px] py-[18px]  z-30  mt-4 text-gray-600'>
                    {item.all_children.map((child, childIndex) => (
                      <li
                        key={child.id}
                        onClick={() => handleNavigate(child)}
                        className='cursor-pointer text-lg text-gray-700 py-2  flex items-center gap-[10px] last:border-none'
                      >
                        <img src={arrowRight} alt="" />{child[NameLangKey]}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )
          ))
        }
      </div>
      <div className='px-[18px] py-[34px] flex flex-col gap-[26px] bg-[#1A213D] text-[#fff]'>
        <p className='flex items-center gap-[8px]'><img src={map} alt="" />{langData.shopping_center} - TOSHKENT</p>
        <p className='flex text-[16px] items-center gap-[8px]'>
          <img src={phone} alt="Phone Icon" />
          +998(97) 757 72 27
        </p>
        <p className='flex items-center gap-[8px]'><img src={clock} alt="" />10:00 AM - 9:00 PM</p>
        <div className='flex items-center w-full'>
          <img src={earth} alt="" />
          <ul className='flex items-center justify-between w-full ml-[6px]'>
            <li onClick={() => handleMenuItemClick("uz")} className={`${lang == "uz" ? "text-[#fff]" : "text-[#A7AAAE]"}`}>O’zbek tili</li>
            <li onClick={() => handleMenuItemClick("ru")} className={`${lang == "ru" ? "text-[#fff]" : "text-[#A7AAAE]"}`}>Русский язык</li>
            <li onClick={() => handleMenuItemClick("en")} className={`${lang == "en" ? "text-[#fff]" : "text-[#A7AAAE]"}`}>English</li>
          </ul>
        </div>
      </div>

    </div>
  );
}

export default SmDropdown;
