import React, { useEffect, useRef, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { icons } from '@utilits/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useAddToFavoritesMutation } from '@services/AllApi';
import { toast } from 'react-toastify';
import { setQuantity, setSelectedSize, setSelectedSizeId } from '../../Slice';

function OurproductMap({ item }) {
    const [activecount, setActivecount] = useState(false);
    const navigate = useNavigate();
    const carouselRef = useRef(null);
    const dispatch = useDispatch();
    const clientToken = localStorage.getItem("clientToken");
    const clientId = localStorage.getItem('clientId');
    const langData = useSelector((state) => state.HomeSlice.langData);
    const lang = useSelector((state) => state.HomeSlice.lang);
    const [addToFavorites, { isSuccess: addToFavoritesSuc }] = useAddToFavoritesMutation();
    
    const NameLangKey = `name_${lang}`;
    const currency = (number, currency, lang = undefined) =>
        Intl.NumberFormat(lang, { style: "currency", currency }).format(number);

    // Preload images to cache them
    useEffect(() => {
        if (item?.files) {
            item.files.forEach(file => {
                const img = new Image();
                img.src = `https://backend.morobolsin.uz/${file.path}`;
                img.onload = () => console.log(`${file.path} preloaded successfully`);
                img.onerror = () => console.error(`Error preloading image: ${file.path}`);
            });
        }
    }, [item?.files]);
    

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (activecount) {
                if (e.key === 'ArrowRight') {
                    const nextButton = carouselRef.current.querySelector('.control-next');
                    nextButton?.click();
                }
                if (e.key === 'ArrowLeft') {
                    const prevButton = carouselRef.current.querySelector('.control-prev');
                    prevButton?.click();
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [activecount]);

    const handleArrowClick = (e) => {
        e.stopPropagation();
    };

    const handleLikeClick = (e, id) => {
        e.stopPropagation();
        addToFavorites({
            method: "addToFavorites",
            params: {
                token: clientToken,
                product_id: id,
                client_id: clientId
            }
        });
    };

    useEffect(() => {
        addToFavoritesSuc && toast.success("Successfully added to favorites");
    }, [addToFavoritesSuc]);

    return (
        <div
            className="relative p-[10px] productCauousel rounded-xl hover:shadow-[0px_4px_14px_0px_#D3D3D340]"
            onMouseEnter={() => setActivecount(true)}
            onMouseLeave={() => setActivecount(false)}
            onClick={() => {
                navigate(`/productDetail/${item.id}`);
                dispatch(setSelectedSize(null));
                dispatch(setSelectedSizeId(''));
                dispatch(setQuantity(1));
            }}
            ref={carouselRef}
        >
            <Carousel
                showArrows={true}
                autoPlay={false}
                interval={2000}
                showStatus={false}
                showIndicators={true}
                showThumbs={false}
                infiniteLoop={true}
                swipeable={true}
                dynamicHeight={true}
                onSwipeEnd={() => console.log("Swipe detected")}
                // onClickItem={(index) => console.log(`Image ${index} clicked`)}
                className="w-full"
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <button
                            type="button"
                            onClick={(e) => {
                                handleArrowClick(e);
                                onClickHandler();
                            }}
                            title={label}
                            className={`control-prev absolute left-0 top-1/2 transform -translate-y-1/2 ${!activecount && 'hidden'}`}
                        >
                            {icons.prev}
                        </button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <button
                            type="button"
                            onClick={(e) => {
                                handleArrowClick(e);
                                onClickHandler();
                            }}
                            title={label}
                            className={`control-next absolute right-0 top-1/2 transform -translate-y-1/2 ${!activecount && 'hidden'}`}
                        >
                            {icons.next}
                        </button>
                    )
                }
            >
                {item?.files.map(file => (
                    <LazyLoadImage
                        key={file.path}
                        src={`https://backend.morobolsin.uz/${file.path}`}
                        alt=""
                        effect="blur"
                        style={{ width: "100%" }}
                        className="rounded-[12px] h-[25vh] md:h-[35vh] lg:h-[45vh] object-cover"
                    />
                ))}
            </Carousel>

            <div className="flex items-center justify-between absolute top-4 w-full px-[10px]">
                {activecount && (
                    <button
                        className="bg-white p-[8px] rounded-full mr-[15px]"
                        onClick={(e) => handleLikeClick(e, item.id)}
                    >
                        {icons.like}
                    </button>
                )}
            </div>

            <div className="flex lg:hidden gap-2 p-2">
                {item?.colors.map((color, index) => (
                    <div
                        key={index}
                        className="border-2 border-[#D7D7D7] rounded-full flex items-center justify-center w-[32px] h-[32px]"
                    >
                        <div
                            className="rounded-full w-[25px] h-[25px] lg:w-[30px] lg:h-[29px]"
                            style={{ backgroundColor: color?.name_en }}
                        ></div>
                    </div>
                ))}
            </div>

            <div className="flex p-2 mt-2 cursor-pointer items-start justify-between">
                <div className="grid">
                    <h2 className="text-[14px] lg:mt-[16px] lg:text-[16px] font-light">{item[NameLangKey]}</h2>
                    <p className="text-[16px] lg:text-[18px] font-medium">
                        {item.price && currency(item.price, 'UZS').replace("UZS", "").replace("soʻm", "").replace(/,/g, " ").slice(0, -3).replace('.', " ")} {langData.sum}
                    </p>
                </div>
                <div className="hidden lg:flex gap-2">
                    {item?.colors.map((color, index) => (
                        <div
                            key={index}
                            className="border border-[#D7D7D7] rounded-full flex items-center justify-center w-[20px] h-[20px]"
                        >
                            <div
                                className="rounded-full w-[18px] h-[18px]"
                                style={{ backgroundColor: color?.name_en }}
                            ></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OurproductMap;
