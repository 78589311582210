import React, { useEffect, useState } from 'react';
import arrLeft from "@assets/icons/arrLeft.svg";
import { useDispatch } from 'react-redux';
import { setLoader } from '@Slice';

function ImageSwiper(props) {
    const dispatch = useDispatch();
    const [mainImage, setMainImage] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleThumbnailClick = (path, index) => {
        const thumbnailPath = `https://backend.morobolsin.uz/${path}`;
        setMainImage(thumbnailPath); // Set thumbnail first
        setCurrentIndex(index);

        // Preload high-res image
        const highResImage = new Image();
        highResImage.src = thumbnailPath;
        highResImage.onload = () => {
            setMainImage(highResImage.src); // Update to high-res image after loading
        };
    };

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            handleThumbnailClick(props.getProductDetailData.result[0]?.files[newIndex].path, newIndex);
        }
    };

    const handleNextClick = () => {
        if (currentIndex < props.getProductDetailData.result[0]?.files.length - 1) {
            const newIndex = currentIndex + 1;
            handleThumbnailClick(props.getProductDetailData.result[0]?.files[newIndex].path, newIndex);
        }
    };

    useEffect(() => {
        if (props.getProductDetailData?.status === true && props.getProductDetailSuc) {
            const initialPath = props.getProductDetailData.result[0]?.files[0]?.path;
            handleThumbnailClick(initialPath, 0); // Initialize with the first image
        }

        if (props.getProductDetailSuc) {
            dispatch(setLoader(false)); // Disable loader when content is ready
        }
    }, [props.getProductDetailData, props.getProductDetailSuc]);

    return (
        <div className='flex'>
            {/* Thumbnails Container */}
            <div className='flex flex-col overflow-y-auto object-cover  w-[20%] h-full max-h-[80vh]'>
                {props.getProductDetailData?.status === true &&
                    props.getProductDetailSuc &&
                    props.getProductDetailData.result[0]?.files.map((item, index) => (
                        <img
                            key={index}
                            src={`https://backend.morobolsin.uz/${item.path}`}
                            alt={`thumbnail-${index}`}
                            className={`cursor-pointer w-full object-cover h-[150px] mb-[12px] rounded-[12px] ${index === currentIndex ? 'border-2 border-black' : ''}`}
                            onClick={() => handleThumbnailClick(item.path, index)}
                        />
                    ))}
            </div>

            {/* Main Image Container */}
            <div className="main-image-container object-cover relative w-[100%] h-[100vh] ml-4">
                {isLoading ? (
                    <div className="w-full h-full flex justify-center items-center bg-gray-200 rounded-[12px]">
                        <p>Loading...</p> {/* Replace with skeleton loader if needed */}
                    </div>
                ) : (
                    <img
                        className="rounded-[12px] w-full h-full object-cover"
                        src={mainImage}
                        alt="main"
                    />
                )}
                <img
                    onClick={handlePrevClick}
                    className={`absolute left-0 top-1/2 transform cursor-pointer -translate-y-1/2 bg-transparent ml-[23px] ${
                        currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    src={arrLeft}
                    alt="Previous"
                />
                <img
                    onClick={handleNextClick}
                    className={`absolute rotate-[180deg] right-0 cursor-pointer top-1/2 transform -translate-y-1/2 bg-transparent mr-[23px] ${
                        currentIndex === props.getProductDetailData?.result[0]?.files.length - 1
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                    }`}
                    src={arrLeft}
                    alt="Next"
                />
            </div>
        </div>
    );
}

export default ImageSwiper;
