import React, { useEffect, useState } from 'react';
import img1 from '@assets/imgs/unnamed.png';
import { useDispatch, useSelector } from 'react-redux';
import { useGetProductDetailMutation } from '../../services/AllApi';
import { setLoader } from '../../Slice';
import { useNavigate } from 'react-router-dom';

function Check({ basketData, productData }) {
    const [detailedProducts, setDetailedProducts] = useState([]);
    const dispatch = useDispatch();
    const langData = useSelector(state => state.HomeSlice.langData)
    const lang = useSelector(state => state.HomeSlice.lang)
    const apiToken = localStorage.getItem("apiToken");
    const navigate = useNavigate()
    const [getProductDetail, { data: getProductDetailData, isLoading: getProductDetailLoading }] = useGetProductDetailMutation();

    // Format currency helper
    const formatCurrency = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const nameLangKey = `name_${lang}`



    // Fetch product details for each basket item
    useEffect(() => {
        if (apiToken) {
            const fetchDetails = async () => {
                const promises = basketData.result.map((basketItem) =>
                    getProductDetail({
                        method: "product_details",
                        params: {
                            id: basketItem.product_id,
                        },
                    }).unwrap()
                );

                const details = await Promise.all(promises);

                const enrichedProducts = basketData.result.map((basketItem, index) => {
                    const product = details[index]?.result;


                    if (!product) {
                        console.warn(`Product with ID ${basketItem.product_id} not found.`);
                        return null;
                    }

                    // Get size from sizes array based on basketItem.size_id
                    const sizeAttribute = product.sizes?.find(
                        (size) => size.id === Number(basketItem.size_id)
                    );

                    const applicablePriceRange = product.price_ranges?.find(
                        (range) =>
                            basketItem.quantity >= parseInt(range.from) &&
                            basketItem.quantity <= parseInt(range.to)
                    );

                    const unitPrice = applicablePriceRange
                        ? applicablePriceRange.price
                        : product.price;

                    return {
                        id: basketItem.id,
                        name: product[nameLangKey],
                        img: product.files?.[0]?.path || "",
                        details: product.colors?.map((color) => color[nameLangKey]).join(", ") || "",
                        size: sizeAttribute?.value,
                        price: formatCurrency(unitPrice),
                        quantity: basketItem.quantity,
                        total: formatCurrency(unitPrice * basketItem.quantity),
                    };
                });

                setDetailedProducts(enrichedProducts.filter(Boolean));
            };

            fetchDetails();
        }
    }, [apiToken, basketData, getProductDetail]);

    // Dispatch loader
    useEffect(() => {
        dispatch(setLoader(getProductDetailLoading));
    }, [getProductDetailLoading, dispatch]);

    // Calculate total price
    const totalPrice = detailedProducts?.reduce((sum, item) => {
        const numericTotal = Number(item.total.replace(/\s/g, ""));
        return sum + numericTotal;
    }, 0) || 0;


    return (
        <div className="flex items-center flex-col w-full">
            <div className="border w-full rounded-t-lg px-[24px] border-[#C9C9C9]">
                {detailedProducts?.map((item) => (
                    <div key={item.id} className="border-b py-[24px]">
                        <div className="flex items-center gap-2 mb-[16px]">
                            <img
                                className="w-[80px] h-auto object-cover rounded-lg border"
                                src={item.img ? `https://backend.morobolsin.uz/${item.img}` : img1}
                                alt={item.name}
                            />
                            <div className="text-sm font-medium">
                                <p>{item.name}</p>
                                <p className="text-[#B6B6B6] whitespace-pre-line leading-5">
                                    {item.size} |  {item.details}
                                </p>
                            </div>
                        </div>
                        <p className="text-[14px] text-[#B6B6B6]">
                            {item.quantity} X{" "}
                            <span className="text-[#1D51FF]">
                                {formatCurrency(item.price)} UZS
                                {item.logoPrice > 0 && ` + ${item.logoPrice}`}
                            </span>
                        </p>
                    </div>
                ))}
            </div>
            <div className="border-l border-r border-b w-full p-[24px] rounded-b-lg border-[#C9C9C9]">
                <h2 className="flex items-center justify-between text-[18px]">
                    {langData.totalPrice}
                    <p className="text-[#1D51FF] font-semibold">
                        {formatCurrency(totalPrice)} UZS
                    </p>
                </h2>
                <button onClick={() => navigate("/order")} className="w-full py-[23px] bg-[#1A213D] text-[#fff] rounded-lg mt-[24px]">
                    {langData.purchase}
                </button>
            </div>
        </div>
    );
}

export default Check;
