import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import penMagic from "@assets/icons/magicpen.svg";
import arrRight from "@assets/icons/arrow-right.svg";
import heart from "@assets/icons/heart.svg";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import EmbroyderyStep1 from './EmbroyderyStep1';
import EmbroyderyStep2 from './EmbroyderyStep2';
import EmbroyderyStep3 from './EmbroyderyStep3';
import EmbroyderyStep4 from './EmbroyderyStep4';
import { useDispatch, useSelector } from 'react-redux';
import { useAddBasketMutation, useAddToFavoritesMutation } from '../../services/AllApi';
import { setSelectSizeErr } from '../../Slice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: { xs: "90%", lg: "60%" },
    height: { xs: "60vh", lg: "80vh" },
    overflowY: "scroll",
    bgcolor: 'background.paper',
    borderRadius: "8px",
    boxShadow: 24,
    p: { xs: "10px", lg: "40px 32px" },
};

function Buttons({ productName, categoryId, productId, getProductDetailData }) {
    const dispatch = useDispatch()
    const [openEmbroidery, setOpenEmbroidery] = useState(false);
    const [steps, setSteps] = useState(1);
    const langData = useSelector((state) => state.HomeSlice.langData);
    const selectedSizeId = useSelector((state) => state.HomeSlice.selectedSizeId);
    const selectedSize = useSelector((state) => state.HomeSlice.selectedSize);
    const quantity = useSelector((state) => state.HomeSlice.quantity);
    const [addToFavorites] = useAddToFavoritesMutation();
    const [addToBasket, { isSuccess: addToBasketSuc }] = useAddBasketMutation();
    const clientToken = localStorage.getItem("clientToken");
    const clientId = localStorage.getItem("clientId");
    const [lineNum, setLineNum] = useState(1)
    const [textLine1, setTextLine1] = useState("")
    const [textLine2, setTextLine2] = useState("")
    const [typeLine1, setTypeLine1] = useState("")
    const [typeLine2, setTypeLine2] = useState("")
    const [selectedFont1, setSelectedFont1] = useState('Times New Roman');
    const [selectedFont2, setSelectedFont2] = useState('Times New Roman');
    const [color1, setColor1] = React.useState("#fff");
    const [color2, setColor2] = React.useState("#fff");

    const hasSizes = getProductDetailData?.status === true && getProductDetailData.result[0].sizes?.length > 0;

    const handleAddToFavoritesFnc = (e) => {
        e.preventDefault();
        addToFavorites({
            method: "addToFavorites",
            params: {
                token: clientToken,
                product_id: productId.productId,
                client_id: clientId,
            },
        });
        toast.success(langData.SuccessfullyFavorites);
    };

    const handleAddToBasketFnc = (e) => {
        e.preventDefault();

        if (hasSizes) {
            if (selectedSize && selectedSizeId) {
                addToBasket({
                    method: "addToBasket",
                    params: {
                        token: clientToken,
                        product_id: productId.productId,
                        size_id: selectedSizeId,
                        quantity: quantity,
                        has_discount: false,
                        discount: null,
                        has_logo: false,
                        client_id: clientId,
                    },
                });
                dispatch(setSelectSizeErr(false));
            } else {
                toast.error(langData.Please_choose_size);
                dispatch(setSelectSizeErr(true));
            }
        } else {
            addToBasket({
                method: "addToBasket",
                params: {
                    token: clientToken,
                    product_id: productId.productId,
                    size_id: null,
                    quantity: quantity,
                    has_discount: false,
                    discount: null,
                    has_logo: false,
                    client_id: clientId,
                },
            });
            dispatch(setSelectSizeErr(false));
        }
    };




    useEffect(() => {
        if (addToBasketSuc) {
            toast.success(langData.SuccessfullyBasket);
        }

    }, [addToBasketSuc])


    const handleCloseEmbroidery = () => {
        setOpenEmbroidery(false);
    }

    const handleOpenEmbroideryModal = () => {
        if (hasSizes) {
            if (selectedSize && selectedSizeId) {
                setOpenEmbroidery(true);
                dispatch(setSelectSizeErr(false));
            } else {
                toast.error(langData.Please_choose_size);
                dispatch(setSelectSizeErr(true));
            }
        } else {
            setOpenEmbroidery(true); // Open embroidery even if no sizes are required.
        }
    };



    return (
        <>
            <div className='lg:w-[50%] mx-auto'>
                {categoryId == 1 && (
                    <p
                        onClick={() => handleOpenEmbroideryModal()}
                        className='flex items-center justify-center w-full cursor-pointer text-[14px] lg:text-[16px] gap-[24px]'
                    >
                        {langData.Add_an_embroidered_name} <img src={penMagic} alt="" />
                    </p>
                )}
                <Modal
                    open={openEmbroidery}
                    onClose={handleCloseEmbroidery}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        {
                            steps == 1 && <EmbroyderyStep1 lineNum={lineNum} setSteps={setSteps} setLineNum={setLineNum} />
                        }
                        {
                            steps == 2 && <EmbroyderyStep2
                                lineNum={lineNum} setSteps={setSteps} textLine1={textLine1}
                                textLine2={textLine2} setTextLine1={setTextLine1} setTextLine2={setTextLine2}
                                typeLine1={typeLine1} typeLine2={typeLine2} setTypeLine1={setTypeLine1} setTypeLine2={setTypeLine2}
                                selectedFont1={selectedFont1} setSelectedFont1={setSelectedFont1}
                                selectedFont2={selectedFont2} setSelectedFont2={setSelectedFont2}
                            />
                        }
                        {
                            steps == 3 && <EmbroyderyStep3
                                color1={color1} setColor1={setColor1}
                                color2={color2} setColor2={setColor2}
                                lineNum={lineNum} setSteps={setSteps} textLine1={textLine1}
                                textLine2={textLine2} setTextLine1={setTextLine1} setTextLine2={setTextLine2}
                                typeLine1={typeLine1} typeLine2={typeLine2} setTypeLine1={setTypeLine1} setTypeLine2={setTypeLine2}
                                selectedFont1={selectedFont1} setSelectedFont1={setSelectedFont1}
                                selectedFont2={selectedFont2} setSelectedFont2={setSelectedFont2}
                            />
                        }
                        {
                            steps == 4 && <EmbroyderyStep4
                                productName={productName}
                                color1={color1}
                                color2={color2}
                                lineNum={lineNum} textLine1={textLine1}
                                textLine2={textLine2}
                                selectedFont1={selectedFont1}
                                selectedFont2={selectedFont2}
                                setSteps={setSteps}
                            />
                        }
                    </Box>
                </Modal>
            </div>
            <button
                onClick={handleAddToBasketFnc}
                className='w-full cursor-pointer flex items-center text-[14px] lg:text-[16px] justify-center mt-[40px] mb-[16px] text-[#fff] bg-[#1A213D] rounded-[8px] py-[20px]'
            >
                {langData.Add_to_Cart} <img className='ml-[24px]' src={arrRight} alt="" />
            </button>
            <button
                onClick={handleAddToFavoritesFnc}
                className='w-full cursor-pointer flex items-center text-[14px] lg:text-[16px] justify-center rounded-[8px] py-[20px] border border-[#B9BBBE]'
            >
                {langData.Add_to_Favorites} <img className='ml-[24px]' src={heart} alt="" />
            </button>
        </>
    );
}

export default Buttons;
