import React, { useEffect } from "react";
import { useGetAllProductsMutation, useGetBasketMutation } from "../../services/AllApi";
import ProductList from "./ProductList";
import Check from "./Check";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../Slice";

function Basket() {
    const clientToken = localStorage.getItem("clientToken");
    const clientId = localStorage.getItem("clientId");
    const langData = useSelector(state => state.HomeSlice.langData)
    const apiToken = localStorage.getItem("apiToken");
    const dispatch = useDispatch();

    const [getBasket, { data: getBasketData, isSuccess: getBasketSuc }] = useGetBasketMutation();

    useEffect(() => {
        getBasket({
            method: "getBasket",
            params: {
                token: clientToken,
                client_id: clientId,
            },
        });
    }, []);

    const [getAllProducts, { data: getAllProductsData, isLoading: getAllProductsLoading, isSuccess: getAllProductsSuc }] = useGetAllProductsMutation();

    const productsBody = {
        method: "get_all_products",
    };

    useEffect(() => {
        if (apiToken) {
            getAllProducts(productsBody);
        }
    }, [apiToken]);

    useEffect(() => {
        getAllProductsLoading && dispatch(setLoader(getAllProductsLoading));
    }, [getAllProductsLoading]);

    useEffect(() => {
        getAllProductsSuc && dispatch(setLoader(!getAllProductsSuc));
    }, [getAllProductsSuc]);

    // Fallback UI for empty basket
    const isBasketEmpty = !getBasketData?.result || getBasketData.result.length === 0;


    // const linkBitrix = "https://b24-gxh37i.bitrix24.kz/rest/1/ns3opd6slsnij6pp/crm.deal.add.json?DEAL_CATEGROY=Chef Jacket&DEAL_PRODUCTNAME=Kitel-786&DEAL_SIZE&QUANTITY={xl-2}&DEAL_SUM=120 000 &DEAL_EMBROIDERYLINE1={text:"Abdulloh",color:"white",font:"sanserif"}&DEAL_EMBROIDERYLINE2={text:"Abdulloh",color:"white",font:"sanserif"}&DEAL_PRODUCTCOLOR=black&DEAL_IMAGELINK=HTTPS://IMAGE.PNG&DEAL_CONTACTNUMBER=+998990008877&DEAL_CONTACTNAME=Abdulloh&DEAL_ADDRESSLINK=https://www.google.com/maps/@41.3213139,69.2771921,16z?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"

    return (
        <div className="container  mx-auto lg:mt-[64px]">
            <h1 className="lg:hidden my-4">Basket</h1>
            <div className="flex flex-col lg:flex-row gap-4">
                {isBasketEmpty ? (
                    <div className="flex flex-col items-center justify-center text-center w-full py-[64px] rounded-lg">
                        <h2 className="text-2xl font-semibold text-gray-700 mb-2">{langData.YourBasketEmpty}</h2>
                        <p className="text-gray-500 mb-4">
                            {langData.AddProductsBasket}
                        </p>
                    </div>
                ) : (
                    <>
                        <div className="flex-2">
                            <ProductList basketData={getBasketData} productData={getAllProductsData} />
                        </div>
                        <div className="w-full lg:w-[35%]">
                            <div className="sticky top-[64px]">
                                <Check basketData={getBasketData} productData={getAllProductsData} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Basket;
