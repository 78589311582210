import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import profileImg from "../../assets/imgs/profile.png";

function Order() {
  const [manzil, setManzil] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [mapPosition, setMapPosition] = useState([41.2995, 69.2401]); // Default: Tashkent coordinates
  const langData = useSelector((state) => state.HomeSlice.langData);
  const [mapInstance, setMapInstance] = useState(null);
  const [placemark, setPlacemark] = useState(null);
  const [iconUrl, setIconUrl] = useState("https://png.pngtree.com/png-vector/20230106/ourmid/pngtree-flat-red-location-sign-png-image_6553065.png"); // Default icon

  useEffect(() => {
    loadYandexScript();
  }, []);

  const loadYandexScript = () => {
    if (
      !document.querySelector(
        "script[src='https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=bf24d7af-18cb-4792-95ca-ff67797a6c36']"
      )
    ) {
      const script = document.createElement("script");
      script.src =
        "https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=bf24d7af-18cb-4792-95ca-ff67797a6c36";
      script.async = true;
      script.onload = initYandexMap;
      document.head.appendChild(script);
    } else {
      initYandexMap();
    }
  };

  const initYandexMap = () => {
    if (window.ymaps) {
      window.ymaps.ready(() => {
        const myMap = new window.ymaps.Map("yandex-map", {
          center: mapPosition,
          zoom: 13,
          controls: ["zoomControl", "typeSelector", "fullscreenControl"],
        });

        const myPlacemark = new window.ymaps.Placemark(
          mapPosition,
          { balloonContent: manzil || "Selected location" },
          {
            iconLayout: "https://png.pngtree.com/png-vector/20230106/ourmid/pngtree-flat-red-location-sign-png-image_6553065.png",
            iconImageHref: iconUrl, // Set the custom icon
            iconImageSize: [50, 50], // Set icon size
            iconImageOffset: [-15, -40], // Set icon offset
            draggable: true, // Placemark is draggable
          }
        );

        myPlacemark.events.add("dragend", () => {
          const coords = myPlacemark.geometry.getCoordinates();
          setMapPosition(coords);
          fetchAddress(coords[0], coords[1]);
        });

        myMap.geoObjects.add(myPlacemark);
        setPlacemark(myPlacemark);

        // Click event handler for the map
        myMap.events.add("click", (e) => {
          const coords = e.get("coords");
          console.log("Map clicked at:", coords);

          setMapPosition(coords); // Update map position state
          fetchAddress(coords[0], coords[1]); // Fetch address based on new coordinates

          // Update placemark position and change its icon
          if (placemark) {
            placemark.geometry.setCoordinates(coords);
            placemark.properties.set("balloonContent", "Selected location");
            placemark.options.set("iconImageHref", iconUrl); // Update icon
          }
        });

        setMapInstance(myMap);
      });
    }
  };

  const fetchAddress = (latitude, longitude) => {
    if (window.ymaps) {
      const geocoder = window.ymaps.geocode([latitude, longitude]);
      geocoder.then((res) => {
        const firstGeoObject = res.geoObjects.get(0);
        const address = firstGeoObject.getAddressLine();
        setManzil(address);

        // Update placemark balloon with address
        if (placemark) {
          placemark.geometry.setCoordinates([latitude, longitude]);
          placemark.properties.set("balloonContent", address);
        }
      });
    }
  };

  const handleManzilChange = (e) => {
    const newAddress = e.target.value;
    setManzil(newAddress);

    if (window.ymaps) {
      const geocoder = window.ymaps.geocode(newAddress);
      geocoder.then((res) => {
        if (res.geoObjects.getLength() > 0) {
          const firstGeoObject = res.geoObjects.get(0);
          const coordinates = firstGeoObject.geometry.getCoordinates();
          setMapPosition(coordinates);

          if (placemark) {
            placemark.geometry.setCoordinates(coordinates);
            placemark.properties.set("balloonContent", newAddress);
          }

          if (mapInstance) {
            mapInstance.setCenter(coordinates, 13);
          }
        }
      });
    }
  };

  const handleGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapPosition([latitude, longitude]);
          if (mapInstance) {
            mapInstance.setCenter([latitude, longitude], 13);
          }
          fetchAddress(latitude, longitude);
        },
        (error) => {
          console.error("Geolocation error:", error);
          alert("Unable to fetch your location. Please select manually.");
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Selected Address:", manzil);
    console.log("Coordinates:", mapPosition);

    // Add further backend integration here
  };

  // Check if all fields are filled
  const isFormValid = name && surname && phone && manzil && mapPosition;

  return (
    <div className="container mx-auto py-8">
      <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
        <div
          className="md:w-1/2 hidden md:block h-[360px] md:h-auto bg-cover bg-left"
          style={{ backgroundImage: `url(${profileImg})` }}
        ></div>
        <div className="md:w-1/2 p-8">
          <h2 className="text-[24px] lg:text-[36px] font-bold mb-6">
            {langData.customerInfo}
          </h2>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="block text-[16px] lg:text-[20px] font-medium text-gray-700">{langData.name}</label>
              <input
                type="text"
                placeholder="Asadulloh"
                className="mt-1 block w-full rounded-md p-[16px] border-[#2C2D30] border text-[16px] lg:text-[20px]"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-[16px] lg:text-[20px] font-medium text-gray-700">{langData.surname}</label>
              <input
                type="text"
                placeholder="Nematov"
                className="mt-1 block w-full rounded-md p-[16px] border-[#2C2D30] border text-[16px] lg:text-[20px]"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-[16px] lg:text-[20px] font-medium text-gray-700">{langData.phoneNumber}</label>
              <input
                type="text"
                placeholder="+998 98 123 45 67"
                className="mt-1 block w-full rounded-md p-[16px] border-[#2C2D30] border text-[16px] lg:text-[20px]"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-[16px] lg:text-[20px] font-medium text-gray-700">
                {langData.address}
              </label>
              <input
                type="text"
                value={manzil}
                onChange={handleManzilChange}
                className="mt-1 block w-full rounded-md p-[16px] border-[#2C2D30] border text-[16px] lg:text-[20px]"
              />
            </div>
            <div
              id="yandex-map"
              style={{ height: "400px", width: "100%" }}
              className="rounded-lg shadow-md"
            ></div>
            <button
              type="submit"
              className="w-full bg-[#1a213d] text-white py-[16px] lg:py-[20px] rounded-md"
              disabled={!isFormValid} // Disable button if the form is not valid
            >
              {langData.order}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Order;
