import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    activresupmenu: false,
    opensearch: false,
    loader: false,
    lang: "ru",
    langData: {},
    quantity: 1,
    selectedSize: null,
    openDropdown: false,
    checkedCategories: [],
    selectedColors: [],
    priceRange: [0, 1000000],
    selectedSizeId: "",
    selectSizeErr: false
}



export const slice = createSlice({
    name: 'HomeSlice',
    initialState,
    reducers: {
        setActivesupmenu: (state, action) => {
            state.activresupmenu = action.payload
        },
        setOpensearch: (state, action) => {
            state.opensearch = action.payload
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setLang: (state, action) => {
            state.lang = action.payload
        },
        setLangData: (state, action) => {
            state.langData = action.payload
        },
        setQuantity: (state, action) => {
            state.quantity = action.payload
        },
        setSelectedSize: (state, action) => {
            state.selectedSize = action.payload
        },
        setOpenDropdown: (state, action) => {
            state.openDropdown = action.payload
        },
        setCheckedCategories: (state, action) => {
            state.checkedCategories = action.payload
        },
        setSelectedColors: (state, action) => {
            state.selectedColors = action.payload
        },
        setPriceRange: (state, action) => {
            state.priceRange = action.payload
        },
        setSelectedSizeId: (state, action) => {
            state.selectedSizeId = action.payload
        },
        setSelectSizeErr: (state, action) => {
            state.selectSizeErr = action.payload
        },
    }
})

export const { setActivesupmenu, setLang, setLangData, setLoader, setOpensearch, setQuantity, setSelectedSize, setOpenDropdown, setCheckedCategories, setSelectedColors, setPriceRange, setSelectedSizeId, setSelectSizeErr } = slice.actions

export default slice.reducer 