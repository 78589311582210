import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect } from "react";
import CryptoJS from 'crypto-js';


const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const decryptValue = (encryptedValue) => {
    try {
        if (!SECRET_KEY || !encryptedValue) return null;
        const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error("Decryption error:", error);
        return null;
    }
};
const encryptedAccessToken = localStorage.getItem("apiToken");
const decryptedAccessToken = decryptValue(encryptedAccessToken);



export const AllApi = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://backend.morobolsin.uz",
    }),

    endpoints: (builder) => ({
        getAllCategory: builder.mutation({
            query: (categories) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: categories,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),

        getOneCategory: builder.mutation({
            query: (category) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: category,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        getBanner: builder.mutation({
            query: (banner) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: banner,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        getBanner2: builder.mutation({
            query: (banner) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: banner,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        getPartners: builder.mutation({
            query: (partners) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: partners,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        postMassageSupport: builder.mutation({
            query: (massage) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: massage,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),

        getAllProducts: builder.mutation({
            query: (product) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: product,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        register: builder.mutation({
            query: (user) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: user,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        verificationOtp: builder.mutation({
            query: (otp) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: otp,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        resendOtp: builder.mutation({
            query: (otp) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: otp,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        loginClient: builder.mutation({
            query: (client) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: client,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        categoryDetails: builder.mutation({
            query: (categoryId) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: categoryId,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        getAllColors: builder.mutation({
            query: (colors) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: colors,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        getProductDetail: builder.mutation({
            query: (detailBody) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: detailBody,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        addToFavorites: builder.mutation({
            query: (favoriteBody) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: favoriteBody,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        getFavorites: builder.mutation({
            query: (favoriteBody) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: favoriteBody,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        removeFavorites: builder.mutation({
            query: (favoriteBody) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: favoriteBody,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        addBasket: builder.mutation({
            query: (basketBody) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: basketBody,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        getBasket: builder.mutation({
            query: (basketBody) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: basketBody,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        deleteBasket: builder.mutation({
            query: (basketBody) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: basketBody,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${decryptedAccessToken}`
                }
            }),
        }),
        tokenUpdate: builder.mutation({
            query: (updateBody) => ({
                url: '/api/update-token',
                method: "POST",
                body: updateBody,
            }),
        }),
        getToken: builder.mutation({
            query: (credentials) => ({
                url: "/api/login",
                method: "POST",
                body: credentials,
                headers: {
                    accept: "application/json",
                },
            }),
        }),
    })

});

export const {
    useGetAllCategoryMutation, useGetOneCategoryMutation,
    useGetBannerMutation, useGetBanner2Mutation,
    useGetPartnersMutation, usePostMassageSupportMutation,
    useGetAllProductsMutation, useRegisterMutation,
    useVerificationOtpMutation, useResendOtpMutation,
    useLoginClientMutation, useCategoryDetailsMutation,
    useGetAllColorsMutation, useGetProductDetailMutation,
    useTokenUpdateMutation, useGetTokenMutation,
    useAddToFavoritesMutation, useGetFavoritesMutation,
    useAddBasketMutation, useRemoveFavoritesMutation,
    useGetBasketMutation, useDeleteBasketMutation
} = AllApi;
