import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductDetailMutation } from '@services/AllApi';
import Description from './Description';
import SelectNumber from './SelectNumber';
import ImageSwiper from './ImageSwiper';
import Buttons from './Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '@Slice';
import ImageSwiperSm from './ImageSwiperSm';

function ProductDetail() {
    const id = useParams();
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.HomeSlice.lang);
    const langData = useSelector((state) => state.HomeSlice.langData);
    const apiToken = localStorage.getItem('apiToken')
    const [getProductDetail, { data: getProductDetailData, isSuccess: getProductDetailSuc, isLoading: getProductDetailLoading }] = useGetProductDetailMutation();

    const getProductDetailBody = {
        method: "product_details",
        params: {
            id: id
        }
    };

    // Fetch product details only when `id` changes
    useEffect(() => {
        if (id && apiToken && !getProductDetailData) {
            getProductDetail(getProductDetailBody);
        }
    }, [apiToken]);

    // Combine loading and success state handling into one effect
    useEffect(() => {
        dispatch(setLoader(getProductDetailLoading || !getProductDetailSuc));

    }, [getProductDetailLoading, getProductDetailSuc]);

    const currency = (number, currency, lang = undefined) =>
        Intl.NumberFormat(lang, { style: "currency", currency }).format(number);

    const NameLangKey = `name_${lang}`;

    const categoryId = getProductDetailData?.result[0]?.categories[0].id

    return (
        <div className='container mx-auto mt-[36px] '>
            <div className='block lg:hidden'>
                <ImageSwiperSm getProductDetailData={getProductDetailData} getProductDetailSuc={getProductDetailSuc} />
            </div>
            <div className='flex gap-[44px]'>
                <div className='flex-col w-[65%] hidden lg:flex'>
                    <ImageSwiper getProductDetailData={getProductDetailData} getProductDetailSuc={getProductDetailSuc} />
                    <div className='mt-20'>
                        {
                            getProductDetailData?.result[0].descriptions.length > 0 &&
                            <Description getProductDetailData={getProductDetailData} getProductDetailSuc={getProductDetailSuc} />
                        }
                    </div>
                </div>
                <div className='py-[32px] w-full lg:w-[50%]'>
                    <h1 className='text-[24px] lg:text-[36px] font-bold font-[inter] mb-[16px]' >
                        {getProductDetailData?.status === true && getProductDetailSuc && getProductDetailData.result[0][NameLangKey]}
                    </h1>
                    <p className='text-[22px] font-medium'>
                        {getProductDetailData?.status === true && getProductDetailSuc && getProductDetailData.result[0].price &&
                            currency(getProductDetailData.result[0].price, 'UZS').replace("UZS", "").replace("soʻm", "")
                                .replace(/,/g, " ").slice(0, -3).replace('.', " ")} {langData.sum}
                    </p>
                    <p className='mt-[24px] lg:mt-[48px] mb-[8px] text-[16px] font-medium font-[inter]'>{langData.Quantity_discount_available}</p>
                    <div className='bg-[#F5F5F5] py-[20px] px-[10px] rounded-[8px] flex items-center justify-between gap-[40px]'>
                        {
                            getProductDetailData?.status === true &&
                            getProductDetailData?.result[0].price_ranges.map((item, index) => (
                                <div>
                                    <p className='text-[12px] lg:text-[14px] text-[#96999E] font-normal'>
                                        {item.from}-{item.to} {langData.pcs}
                                    </p>
                                    <h2 key={index} className='text-[14px] flex items-center gap-1  mt-2  lg:text-[20px] font-semibold'>
                                        {currency(item.price, 'UZS').replace("UZS", "").replace("soʻm", "")
                                            .replace(/,/g, " ").slice(0, -3).replace('.', "")}
                                        <p className='text-[14px]  lg:text-[20px] font-semibold'>
                                            {langData.sum}
                                        </p>
                                    </h2>
                                </div>
                            ))
                        }
                    </div>
                    <SelectNumber productId={id} getProductDetailData={getProductDetailData} getProductDetailSuc={getProductDetailSuc} />
                    <div className='lg:mb-20 lg:hidden'>
                        <Description getProductDetailData={getProductDetailData} getProductDetailSuc={getProductDetailSuc} />
                    </div>
                    <Buttons productId={id} categoryId={categoryId} getProductDetailData={getProductDetailData} productName={getProductDetailData?.result[0][NameLangKey]} id={id} />
                </div>
            </div>
        </div >
    );
}

export default ProductDetail;
