import React, { useState } from 'react'
import { icons } from '@utilits/icons'
import bgShadow from "@assets/icons/bgShadow.svg"
import hat from "@assets/imgs/image42.png"
import kitel from "@assets/imgs/Remove-bg12.png"
import fartuk from "@assets/imgs/Remove-bg32.png"
import { useSelector } from 'react-redux'

export default function Order() {
  const [orderone, setorderone] = useState('')
  const langData = useSelector((state) => state.HomeSlice.langData)
  return (
    <div className='container mx-auto my-[64px] lg:my-[80px]'>
      <div className="flex items-center flex-col lg:flex-row gap-4 lg:gap-1  justify-between lg:min-h-[256px]">
        <div onMouseEnter={() => setorderone('1')} onMouseLeave={() => setorderone('')} className={`rounded-lg lg:rounded-l-lg w-full lg:w-1/3 py-[20px] px-[12px] lg:p-6 duration-200 lg:min-h-[256px] ${orderone == '1' ? 'text-white bg-[#1A213D]' : 'text-black bg-[#F5F5F5]'}`}>
          <span className=''>{icons.medal}</span>
          <div className="flex lg:mt-[66px] items-center justify-between">
            <p className=' text-[24px] mt-[36px] lg:mt-0 font-light lg:leading-9'>{langData.personalizeLogo}</p>
            {/* <button className={`bg-white text-black p-4 rounded-full ${orderone == '1' ? "block" : 'hidden'} `}>{icons.arrowright}</button> */}
          </div>
        </div>
        <div onMouseEnter={() => setorderone('2')} onMouseLeave={() => setorderone('')} className={`rounded-lg flex items-center lg:rounded-l-lg w-full  py-[20px] px-[12px] lg:p-6 duration-200 lg:min-h-[256px] ${orderone == '2' ? 'text-white bg-[#1A213D]' : 'text-black bg-[#F5F5F5]'}`}>
          <div>
            <span className=''>{icons.star}</span>
            <div className="flex lg:mt-[66px] items-center justify-between">
              <p className='w-2/3 text-[24px] mt-[36px] lg:mt-0  font-light lg:leading-9'>{langData.prepareUniforms}</p>
            </div>
          </div>
          <div
            className="lg:flex items-center mr-[30px] hidden "
            style={{
              backgroundImage: `url(${bgShadow})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <img src={hat} alt="" />
            <img src={kitel} alt="" />
            <img src={fartuk} alt="" />
          </div>
        </div>
        {/* <div onMouseEnter={() => setorderone('3')} onMouseLeave={() => setorderone('')} className={`rounded-lg lg:rounded-r-lg w-full lg:w-1/3 py-[20px] px-[12px] lg:p-6 duration-200 lg:min-h-[256px] ${orderone == '3' ? 'text-white bg-[#1A213D]' : 'text-black bg-[#F5F5F5]'}`}>
          <span className=''>{icons.comment}</span>
          <div className="flex lg:mt-[66px] items-center justify-between">
            <p className='w-2/3 text-[24px] mt-[36px] lg:mt-0  font-light lg:leading-9'>{langData.canHelp}</p>
            <button className={`bg-white text-black p-4 rounded-full ${orderone == '3' ? "block" : 'hidden'} `}>{icons.arrowright}</button>
          </div>
        </div> */}
      </div>
    </div>
  )
}
