import React, { useState } from 'react';
import leftArrWhite from "@assets/icons/arrow-right.svg";
import arrowDown from "@assets/icons/arrow-down.svg";
import { useSelector } from 'react-redux';

// Expanded list of font options
const fontOptions = [
    'Times New Roman',
    'Arial',
    'Courier New',
    'Georgia',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Comic Sans MS',
    'Impact',
    'Arial Black',
    'Poppins',
];

function EmbroyderyStep2({ setSteps, lineNum, textLine1, textLine2, setTextLine1, setTextLine2, selectedFont1, setSelectedFont1, selectedFont2, setSelectedFont2 }) {
    const langData = useSelector(state => state.HomeSlice.langData);

    // Check if inputs are valid
    const isNextDisabled = !textLine1 || (lineNum > 1 && !textLine2);

    return (
        <div className='flex flex-col'>
            <h1 className='font-medium text-[20px] lg:text-3xl'>
                {langData.Type_the_text_and_choose_the_font}
            </h1>
            <p className='text-[14px] lg:text-xl mt-3 mb-4 lg:my-4 text-[#84888E]'>
                {langData.Youve_chosen_a_line}
            </p>
            <div className='relative bg-embroideryBgImg bg-cover lg:w-3/4 h-[50vh] bg-center'>
                <div className='bg-[#fff] h-[25px] max-w-[120px] lg:max-w-[163px] ml-[31%] mt-[45%] lg:mt-[35%] flex items-center justify-center'>
                    <p style={{ fontFamily: selectedFont1 }}>{textLine1}</p>
                </div>
                {lineNum > 1 && (
                    <div className='bg-[#fff] h-[25px] max-w-[120px] lg:max-w-[163px] ml-[31%] mt-[12px] flex items-center justify-center'>
                        <p style={{ fontFamily: selectedFont2 }}>{textLine2}</p>
                    </div>
                )}
            </div>
            <form className='flex flex-col mt-[20px] lg:mt-[32px]' action="">
                <div className='lg:w-3/4'>
                    <p className='text-base text-[#84888E]'>
                        {langData.first_line_text_and_font}
                    </p>
                    <div className='flex items-center gap-3'>
                        <input
                            autoFocus
                            className='border border-[#B9BBBE] py-[16px] lg:py-[19px] px-[16px] rounded-[4px] mt-[8px] text-[16px] w-full'
                            onChange={(e) => setTextLine1(e.target.value)}
                            required
                            value={textLine1 || ""}
                            maxLength={15}
                            type="text"
                        />
                        <div className='relative border border-[#B9BBBE] py-[16px] lg:py-[19px] px-[16px] rounded-[4px] mt-[8px] text-[14px] lg:text-[16px] w-full flex items-center justify-between'>
                            <p>{selectedFont1.slice(0, 10)}{selectedFont1.length > 10 && "..."}</p>
                            <img src={arrowDown} alt="" />
                            <select
                                value={selectedFont1}
                                onChange={(e) => setSelectedFont1(e.target.value)}
                                className='absolute inset-0 opacity-0 cursor-pointer'
                            >
                                {fontOptions.map((font) => (
                                    <option key={font} value={font}>{font}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                {lineNum > 1 && (
                    <div className='mt-[24px] lg:w-3/4'>
                        <p className='text-base text-[#84888E]'>
                            {langData.second_line_text_and_font}
                        </p>
                        <div className='flex items-center gap-3'>
                            <input
                                className='border border-[#B9BBBE] py-[16px] lg:py-[19px] px-[16px] rounded-[4px] mt-[8px] text-[16px] w-full'
                                onChange={(e) => setTextLine2(e.target.value)}
                                required
                                maxLength={15}
                                value={textLine2 || ""}
                                type="text"
                            />
                            <div className='relative border border-[#B9BBBE] py-[16px] lg:py-[19px] px-[16px] rounded-[4px] mt-[8px] text-[14px] lg:text-[16px] w-full flex items-center justify-between'>
                                <p>{selectedFont2.slice(0, 10)}{selectedFont2.length > 10 && "..."}</p>
                                <img src={arrowDown} alt="" />
                                <select
                                    value={selectedFont2}
                                    onChange={(e) => setSelectedFont2(e.target.value)}
                                    className='absolute inset-0 opacity-0 cursor-pointer'
                                >
                                    {fontOptions.map((font) => (
                                        <option key={font} value={font}>{font}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                )}
                <div className='flex items-center justify-between mt-8'>
                    <img onClick={() => setSteps(1)} className='w-9 h-9 rotate-[90deg] cursor-pointer' src={arrowDown} alt="" />
                    <button
                        onClick={() => !isNextDisabled && setSteps(3)}
                        disabled={isNextDisabled}
                        className={`p-3 lg:p-6 text-[#fff] flex items-center gap-6 rounded-lg self-end ${isNextDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-[#1A213D]"} `}
                    >
                        {langData.nextButton} <img src={leftArrWhite} alt="" />
                    </button>
                </div>
            </form>
        </div>
    );
}

export default EmbroyderyStep2;
